var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "text-align": "right" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _vm._v(" " + _vm._s(_vm.flowObj.customName) + " "),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入审核意见" },
                    model: {
                      value: _vm.postObj.verificationOpinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.postObj, "verificationOpinion", $$v)
                      },
                      expression: "postObj.verificationOpinion",
                    },
                  }),
                  _c("verify-status", {
                    model: {
                      value: _vm.postObj.verificationFinally,
                      callback: function ($$v) {
                        _vm.$set(_vm.postObj, "verificationFinally", $$v)
                      },
                      expression: "postObj.verificationFinally",
                    },
                  }),
                  _vm.postObj.verificationFinally == "3"
                    ? _c(
                        "el-select",
                        {
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.postObj.NodeRejectType,
                            callback: function ($$v) {
                              _vm.$set(_vm.postObj, "NodeRejectType", $$v)
                            },
                            expression: "postObj.NodeRejectType",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.postObj.NodeRejectType == "2" &&
                  _vm.postObj.verificationFinally == "3"
                    ? _c(
                        "el-select",
                        {
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.postObj.NodeRejectStep,
                            callback: function ($$v) {
                              _vm.$set(_vm.postObj, "NodeRejectStep", $$v)
                            },
                            expression: "postObj.NodeRejectStep",
                          },
                        },
                        _vm._l(_vm.FlowNodes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _vm.postObj.verificationFinally == "1" &&
                  _vm.postObj.NodeDesignateType === "RUNTIME_SPECIAL_USER"
                    ? _c("select-users", {
                        attrs: {
                          placeholder: "*选择下一个节点执行用户",
                          userNames: _vm.postObj.NodeDesignateTxts,
                          users: _vm.postObj.NodeDesignates,
                          "ignore-auth": true,
                        },
                        on: {
                          "update:userNames": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "update:user-names": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "users-change": _vm.usersChange,
                        },
                      })
                    : _vm._e(),
                  _vm.postObj.verificationFinally == "1" &&
                  _vm.postObj.NodeDesignateType === "RUNTIME_SPECIAL_ROLE"
                    ? _c("select-roles", {
                        attrs: {
                          placeholder: "*选择下一个节点执行角色",
                          userNames: _vm.postObj.NodeDesignateTxts,
                          roles: _vm.postObj.NodeDesignates,
                          "ignore-auth": true,
                        },
                        on: {
                          "update:userNames": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "update:user-names": function ($event) {
                            return _vm.$set(
                              _vm.postObj,
                              "NodeDesignateTxts",
                              $event
                            )
                          },
                          "roles-change": _vm.rolesChange,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: "2" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "success" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v("提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.flowObj.frmType == 1
        ? _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(_vm.flowObj.dbName != "" && _vm.flowObj.dbName + "Detail", {
                ref: "frmData",
                tag: "component",
                attrs: {
                  frmData: _vm.flowObj.frmData,
                  canWriteFormItemIds: _vm.canWriteFormItemIds,
                },
                on: {
                  "update:frmData": function ($event) {
                    return _vm.$set(_vm.flowObj, "frmData", $event)
                  },
                  "update:frm-data": function ($event) {
                    return _vm.$set(_vm.flowObj, "frmData", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.flowObj.frmType == 2
        ? _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              { staticStyle: { "padding-right": "25px" } },
              [
                _vm.frmContentData.list && _vm.frmContentData.list.length > 0
                  ? _c("CreatedForm", {
                      ref: "createdForm",
                      attrs: {
                        insite: true,
                        data: _vm.frmContentData,
                        value: _vm.defaultValue,
                        canWriteFormItemIds: _vm.canWriteFormItemIds,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c("el-card", { staticClass: "box-card" }, [
            _c("form", { ref: "frmData" }, [
              _vm.canWriteFormItemIds && _vm.canWriteFormItemIds.length > 0
                ? _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.flowObj.frmHtml) },
                  })
                : _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.flowObj.frmPreviewHtml) },
                  }),
            ]),
          ]),
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticStyle: { height: "500px" } },
          [
            _c("CreatedFlow", {
              ref: "createdFlow",
              attrs: {
                "form-template": _vm.currentForm,
                isEdit: true,
                isShowContent: _vm.isShowContent,
                "scheme-content": _vm.flowObj.schemeContent,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }